import snakeCase from 'lodash/snakeCase'
import { IResult } from 'ua-parser-js'

import { getEventAnonymousId } from '~/events/utils/eventAnonymousId'
import { getEventSessionId } from '~/events/utils/eventSessionId'

export const getOrySubmitUrl = ({ submitUrl, utm, uaResults }: { utm: any; submitUrl: string; uaResults: IResult }) => {
  const transientPayload = {
    session_id: getEventSessionId(),
    platform: snakeCase(uaResults.device.type || 'desktop'),
    app_name: 'lumosity2_web',
    anonymous_id: getEventAnonymousId(),
    ...(utm && typeof utm === 'object' && { utm }),
  }
  let orySubmitUrl = submitUrl
  const queryChar = orySubmitUrl.includes('?') ? '&' : '?' // checking if there is already a query string, if not, add one
  orySubmitUrl += `${queryChar}transient_payload=${encodeURIComponent(JSON.stringify(transientPayload))}`
  return orySubmitUrl
}
