import { useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'

import { currentUserVar } from '~/reactive-vars'

export function useHomePageRedirect() {
  const router = useRouter()
  const currentUser = useReactiveVar(currentUserVar)
  const [isComplete, setIsComplete] = useState<boolean>(false)

  useEffect(() => {
    if (router.isReady) {
      if (currentUser?.id && !isComplete) {
        router.push('/')
      }
      setIsComplete(true)
    }
  }, [router, currentUser?.id, isComplete])

  return null
}
